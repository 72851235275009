@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
$font: 'Montserrat', sans-serif;
$red: #df0116;
$orange: #ec8924;
$light: #ffffff;
$grey: #666666;
$primary: #212d51;

body,
html {
	height: 100%;
}

body {
	font-family: $font;
	color: $primary;
}

h2 {
	font-size: 1.5rem;
}

h3 {
	font-size: 1.17rem;
}

.logo {
	max-width: 100%;
}

.text-primary {
	color: $primary !important;
}

.text-warning {
	color: $orange !important;
}

.access-button {
	background-color: $orange;
	color: $light;
	font-weight: bold;
	font-size: 3.7rem;
	margin: 10px;
	text-decoration: none;
	border-radius: 20px;
	display: inline-block;
	padding: 20px 35px;
	text-transform: uppercase;
	letter-spacing: 3px;
	max-width: 350px;
	line-height: 1.2;

	&:disabled {
		background-color: $grey;
		color: $light;
		opacity: 0.8;
		cursor: not-allowed !important;
	}

	&:hover {
		background-color: darken($color: $orange, $amount: 10);
		color: $light !important;
	}
}

.valid-from {
	font-size: 100%;
	font-weight: 600;
}

.list {
	text-align: justify;
	padding: 30px;

	li {
		line-height: 24px;
		padding-bottom: 1rem;
	}
}

.main {
	width: 100%;
	height: 100%;
	max-width: 600px;
}

@media screen and (max-width: 390px) {
	.access-button {
		font-size: 2.5rem;
	}

	.main {
		max-width: 330px !important;
	}

	.list li {
		text-align: left;
	}
}